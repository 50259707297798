<template>
  <div class="wrapper">
    <div class="section page-header header-filter">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">CMS Easy Pay | AGB</h4>
              <div slot="description">
                <ul><li>CMS Easy Pay (ehemalig: CMS Events) ist eine reine Bezahlplattform für Privatveranstaltugen betrieben von der Erlebnisräume GmBH.</li><li>Die AGB sind Grundlage für die Benutzung des Services. Mit der Benutzung der Plattform und insbesondere dem Betätigen der Schaltfläche “Jetzt Bezahlen” anerkennt der Kunde die AGB und hält sich an sie.</li><li>Der Zweck jeder Zahlung ist beschrieben und wird durch den Kunden bei Zahlung akzeptiert. Es besteht kein Anspruch auf Rückerstattung durch Erlebnisräume GmBH.</li><li>Zahlungen werden über den vom Kunden ausgewählten Service getätigt. Erlebnisräume GmBH hat keinen Einfluss auf Fehler der von diesen Anbietern ausgeht.</li><li>Jeder Link ist einzigartig und auf den Kunden angepasst, die Inhalte gelten dadurch als vertraulich und dürfen nicht an Drittpersonen weitergeben werden.</li><li>Nimmt der Kunde an einer Fremdveranstaltung teil, welche über die Bezahlplattform Erlebnisräume GmBH abrechnet, hat sich der Kunde bei Problemen, die nicht die Plattform betreffen, direkt an den Veranstalter zu wenden.</li><li>Erlebnisräume GmBH prüft die Angebote nach bestem Wissen und Gewissen, lehnt aber sämtliche Haftung für Inhalt, Links und Ablauf ab.</li><li>Erlebnisräume GmBH behält sich vor, die AGB’s jederzeit anzupassen und zu ändern. Diese Änderungen werden den Benutzern des Service mitgeteilt.</li><li>Es ist Schweizer Recht anwendbar.</li><li>Bei Streitigkeiten im Zusammenhang mit dem Service sind nach Wahl von Erlebnisräume GmBH die Gerichte am Sitz von Erlebnisräume GmBH oder am Wohnort des Kunden zuständig.</li></ul>
              </div>
            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginCard from '@/components/LoginCard'

export default {
  components: {
    LoginCard,
  }
};
</script>

<style lang="css">
li {
  margin-bottom: 5px;
}
ul {
  padding-left: 10px;
  font-size: 0.75rem;
  line-height: 1.1;
  max-height: 75vh;
  overflow: auto;
}
</style>
