<template>
  <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header :class="getClass(headerColor)">
      <slot name="title"></slot>
      <div class="social-line">
        <slot name="buttons"></slot>
      </div>
    </md-card-header>

    <md-card-content>
      <slot name="description"></slot>
      <slot name="inputs"></slot>
      <slot name="content"></slot>
    </md-card-content>

    <md-card-actions>
      <slot name="footer"></slot>
    </md-card-actions>

    <div class="outside-footer">
      <router-link to="/impressum">Impressum</router-link> | <router-link to="/agb">AGBs</router-link>
    </div>
  </md-card>
</template>

<script>
export default {
  name: "login-card",
  props: {
    headerColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      cardHidden: true
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  methods: {
    showCard: function() {
      this.cardHidden = false;
    },
    getClass: function(headerColor) {
      return "md-card-header-" + headerColor + "";
    }
  }
};
</script>

<style lang="css">
  .outside-footer {
    position: absolute;
    color: white;
    text-align: center;
    right: 0;
    left: 0;
    margin-top: 5px;
    font-size: 0.75rem;
  }

  .outside-footer a, 
  .outside-footer a:link, 
  .outside-footer a:visited {
    color: white !important;
  }
</style>
