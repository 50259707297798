import UserService from '@/services/api/UserService'
import PaymentService from '@/services/api/PaymentService'
import EventService from '@/services/api/EventService'
//import PaymentProcessService from '@/services/api/PaymentProcessService'
nacl.sealedbox = require('tweetnacl-sealedbox-js')

import nacl from 'tweetnacl'
import {
  decodeUTF8,
  encodeUTF8,
  encodeBase64,
  decodeBase64
} from 'tweetnacl-util'

import moment from 'moment'

//const baseUrl = 'http://localhost:8001'
//const baseUrl = 'https://www.cmsevents.ch'


const decryptSealedData = (key, data) => {
  return encodeUTF8(nacl.sealedbox.open(decodeBase64(data), decodeBase64(key.publicKey), decodeBase64(key.secretKey)))
}
const encryptData = (key, data) => {
  let nonce = nacl.randomBytes(nacl.secretbox.nonceLength)
  return encodeBase64(nacl.secretbox(decodeUTF8(data), nonce, decodeBase64(key))) + '|' + encodeBase64(nonce)
}
const decryptData = (key, data) => {
  let d = data.split('|')
  return encodeUTF8(nacl.secretbox.open(decodeBase64(d[0]), decodeBase64(d[1]), decodeBase64(key)))
}


const AdminService = {
    token: null,
    key: null,
    userHash: null,

    checkForSavedSession() {
        let k = atob(localStorage.getItem('s') || '').split('|')
        if(k && k.length == 3)
            return k;
    },

    login(user, pin, key) {
        return UserService.login({
                'user': user,
                'pin': pin
            })
            .then(r => r.data)
            .then(d => {
                if(d.success) {
                    localStorage.setItem('s', btoa([user,pin,key].join('|')))
                    AdminService.userHash = user
                    AdminService.token = d.token
                    AdminService.key = key
                    AdminService.keypair = null

                    return UserService.getKeyPair(AdminService.token)
                        .then(r => r.data)
                        .then(d => {
                            if(!d.keypair) {
                                let kp = nacl.box.keyPair();
                                return UserService.setKeyPair(AdminService.token, {
                                    keypair: encryptData(AdminService.key, [kp.publicKey, kp.secretKey].map(i => encodeBase64(i)).join('|'))
                                }).then(() => true)
                            }

                            AdminService.keypair = { publicKey: null, secretKey: null };
                            [AdminService.keypair.publicKey, AdminService.keypair.secretKey] = decryptData(AdminService.key, d.keypair).split('|').map(i => i);
                            return true
                        })
                } else {
                    AdminService.userHash = null
                    AdminService.token = null
                    AdminService.key = null
                    AdminService.keypair = null
                    return false
                }
            })
    },

    logout() {
        localStorage.setItem('s', '')
    },

    decryptPayment_(payment) {
        var pk =  ''
        if(payment.payment_key.startsWith('$PUB$'))
            pk = decryptSealedData(AdminService.keypair, payment.payment_key.substring(5))
        else
            pk = decryptData(AdminService.key, payment.payment_key)

        if(pk)
            return ({
                id: payment.payment_hash,
                key: pk,
                payment_key: pk,
                payment_method: payment.payment_method,
                status: payment.payment_status || payment.status,
                payed_at: payment.payed_at ? moment(payment.payed_at)
                          : payment.payment_date ? moment(payment.payment_date) : '',
                date: payment.date ? moment(decryptData(pk, payment.date)) : moment('1900-01-01'),
                name: payment.recipient ? decryptData(pk, payment.recipient) : '',
                usage: payment.title ? decryptData(pk, payment.title) : '',
                amount: payment.price || 0,
                description: payment.description ? decryptData(pk, payment.description) : '',
                event_uid: payment.event_uid
            })
        else
            return null
    },

    getAllEvents(page) {

        if(AdminService.token) {
            return EventService.getAll(AdminService.token, page)
                .then(r => r.data)
                .then(paginatedData => ({
                        ...paginatedData,
                        data: paginatedData.data.map(i => ({
                                id: i.id,
                                name: i.title ? decryptData(AdminService.key, i.title) : '',
                                event_date: i.date ? decryptData(AdminService.key, i.date) : '',
                            })).filter(i => i)
                    }))
        } else 
            return Promise.resolve([])
    },
    saveEvent(id, name, event_date, description) {
        if(AdminService.token) {
            let data = {
                user_hash: AdminService.userHash,
                title: name ? encryptData(AdminService.key, name) : '',
                date: event_date ? encryptData(AdminService.key, event_date) : '',
                description: description ? encryptData(AdminService.key, description) : '',
            }
            if(!id)  {
                return EventService.create(AdminService.token, data)
                    .then(res => res.data)
                    .then(data => ({
                        id: data.id,
                    }))
            } else
                return EventService.update(AdminService.token, id, data)
                    .then(res => res.data)
                    .then(data => ({
                        id: data.id
                    }))
        } else 
            return Promise.resolve({})
    },
    getEvent(id) {
        if(AdminService.token) {
            return EventService.get(AdminService.token, id)
                .then(r => r.data)
                .then(i => {
                    return PaymentService.getByEvent(AdminService.token, i.id)
                        .then(r => r.data)
                        .then(payments => ({
                            id: i.id,
                            name: i.title ? decryptData(AdminService.key, i.title) : '',
                            event_date: i.date ? decryptData(AdminService.key, i.date) : '',
                            description: i.description ? decryptData(AdminService.key, i.description) : '',
                            payments: payments.map(i => AdminService.decryptPayment_(i)).filter(i => i)
                        }))
                })
        } else 
            return Promise.resolve(null)
    },
    deleteEvent(id) {
        if(AdminService.token) {
            return EventService.destroy(AdminService.token, id)
                .then(r => r.data)       
        } else 
            return Promise.resolve(null)
    },

    getAllPayments(page) {
        if(AdminService.token) {
            return PaymentService.getAll(AdminService.token, page)
                .then(r => r.data)
                .then(paginatedData => ({
                    ...paginatedData,
                    data: paginatedData.data.map(i => AdminService.decryptPayment_(i)).filter(i => i)
                }))
        } else 
            return Promise.resolve([])
    },

    getPayment(id) {
        if(AdminService.token) {
            return PaymentService.get(AdminService.token, id)
                .then(r => r.data)
                .then(i => AdminService.decryptPayment_(i))
        } else 
            return Promise.resolve(null)
    },
    deletePayment(id) {
        if(AdminService.token) {
            return PaymentService.destroy(AdminService.token, id)
                .then(r => r.data)       
        } else 
            return Promise.resolve(null)
    },
    cashPayment(id) {
        if(AdminService.token) {
            return PaymentService.cashPayment(AdminService.token, id)
                .then(r => r.data)       
        } else 
            return Promise.resolve(null)
    },
    generateKey() {
        return encodeBase64(nacl.randomBytes(nacl.secretbox.keyLength))
    },

    savePayment(paymentHash, name, usage, amount, description, date, key, event_uid) {
        if(AdminService.token) {
            let data = {
                user_hash: AdminService.userHash,
                recipient: name ? encryptData(key, name) : '',
                title: usage ? encryptData(key, usage) : '',
                description: description ? encryptData(key, description) : '',
                date: date ? encryptData(key, date) : '',
                price: Number(amount),
                payment_key: encryptData(AdminService.key, key),
            }
            if(!paymentHash)  {
                data.event_uid = event_uid
                data.date = encryptData(key, moment().toISOString())
                return PaymentService.create(AdminService.token, data)
                    .then(res => res.data)
                    .then(data => ({
                        id: data.payment_hash,
                    }))
            } else
                return PaymentService.update(AdminService.token, paymentHash, data)
                    .then(res => res.data)
                    .then(data => ({
                        id: data.payment_hash,
                    }))
        } else 
            return Promise.resolve({})
    },

    
}


export default AdminService
