import http from './http-common'

const getAll = (token, page) => {
  return http.get(`merchant-api/payment?api_token=${token}&page=${page}`)
}

const getByEvent = (token, event) => {
  return http.get(`merchant-api/event/${event}/payments?api_token=${token}`)
}

const create = (token, data) => {
  return http.post(`merchant-api/payment?api_token=${token}`, data)
}

const get = (token, id) => {
  return http.get(`merchant-api/payment/${id}?api_token=${token}`)
}

const update = (token, id, data) => {
  return http.patch(`merchant-api/payment/${id}?api_token=${token}`, data)
}

const cashPayment = (token, id, data) => {
  return http.patch(`merchant-api/payment/${id}/cash?api_token=${token}`, data)
}

const destroy = (token, id) => {
  return http.post(`merchant-api/payment/${id}/delete?api_token=${token}`)
}

export default {
  getAll,
  getByEvent,
  get,
  create,
  update,
  destroy,
  cashPayment
}
