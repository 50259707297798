<template>
    <div class="wrapper">
    <div class="section page-header header-filter">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">CMS Easy Pay</h4>
              <p slot="description" class="description">Scannen Sie Ihren Code</p>
              <md-field slot="inputs" style="display: flex; justify-content: center;">
                <code-scanner class="qrscanner" @scanned="onNewScan" />
              </md-field>
              <md-field slot="inputs">
                <label>oder geben Sie den Code hier ein</label>
                <md-input v-model="paymentCode"></md-input>
              </md-field>
              <md-button slot="footer" class="md-simple md-success md-lg" @click="onSend">
                Senden
              </md-button>
            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from '@/services/AdminService'
import LoginCard from '@/components/LoginCard'
import CodeScanner from '@/components/CodeScanner'

export default {
  components: {
    LoginCard,
    CodeScanner
  },
  data() {
    return {
      paymentCode: '',
    };
  },
  props: {
  },
  computed: {
  },
  methods: {
    onSend() {
      this.processCodes(this.paymentCode)
    },
    onNewScan(ev) {
      this.processCodes(ev)
    },
    processCodes(ev) {
      if(ev.indexOf('chant/#') !== -1) {
        let x = ev.split('chant/#')[1].split('|')
        ev = 'A|'+x[0]+'|'+prompt('Enter PIN')+'|'+x[1]
      }

      let k = ev.split('|')
      if(k[0] == 'A') {
        AdminService.login(k[1],k[2],k[3]).then(s => {
          if(s)
            this.$router.push({ path: '/admin/events' })
        })
      } else {
        let pev = ev.split('/pay/')
        if(pev.length == 1)
          this.$router.push({ path: '/pay/'+pev[0] })
        if(pev.length == 2)
          this.$router.push({ path: '/pay/'+pev[1] })
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      let s = AdminService.checkForSavedSession()
      if(s) {
        AdminService.login(s[0],s[1],s[2]).then(s => {
          if(s)
            vm.$router.push({ path: '/admin/events' })
        })
      }
    })
  },
};
</script>

<style lang="css">
.qrscanner {
  margin: 7px;
  background-color: black;
}
</style>
