import http from './http-common'

const getAll = (token, page) => {
  return http.get(`merchant-api/event?api_token=${token}&page=${page}`)
}

const create = (token, data) => {
  return http.post(`merchant-api/event?api_token=${token}`, data)
}

const get = (token, id) => {
  return http.get(`merchant-api/event/${id}?api_token=${token}`)
}

const update = (token, id, data) => {
  return http.patch(`merchant-api/event/${id}?api_token=${token}`, data)
}

const destroy = (token, id) => {
  return http.post(`merchant-api/event/${id}/delete?api_token=${token}`)
}

export default {
  getAll,
  get,
  create,
  update,
  destroy,
}
