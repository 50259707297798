<template>
    <div class="wrapper">
    <div class="section page-header header-filter">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">CMS Easy Pay | Impressum</h4>
              <div slot="description">
                Erlebnisräume GmbH<br>c/o first-accounting.com Ltd liab co<br>Blegistrasse 11B<br>6340 Baar<br><br>info(at)erlebnisraeume.ch
              </div>
            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginCard from '@/components/LoginCard'

export default {
  components: {
    LoginCard,
  }
};
</script>

<style lang="css">
</style>
