import http from './http-common'

const getAll = (token) => {
    return http.get(`merchant-api/user?api_token=${token}`)
}

const create = (token, data) => {
    return http.post(`merchant-api/user?api_token=${token}`, data)
}

const get = (token, id) => {
    return http.get(`merchant-api/user/${id}?api_token=${token}`)
}

const update = (token, id, data) => {
    return http.patch(`merchant-api/user/${id}?api_token=${token}`, data)
}

const destroy = (token, id) => {
    return http.delete(`merchant-api/user/${id}?api_token=${token}`)
}

const login = (data) => {
    return http.post(`merchant-api/user/login`, data)
}

const getKeyPair = (token) => {
    return http.get(`merchant-api/user/keypair?api_token=${token}`)
}
const setKeyPair = (token, data) => {
    return http.patch(`merchant-api/user/keypair?api_token=${token}`, data)
}

export default {
    getAll,
    get,
    create,
    update,
    destroy,
    login,
    setKeyPair,
    getKeyPair,
}
